
import currencyIcons from '../fixtures/currencyIcons';

const SingleValue = ({ data }) => (
    <>
      {currencyIcons[data.label] ? (
        <img
          src={currencyIcons[data.label]}
          className="currency-svg-icon"
          alt={data.label}
        />
      ) : null}
      {data.label}
    </>
  );
  

export {SingleValue};
