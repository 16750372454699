// components/KYC.js

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShieldAlt } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

const KYC = () => {
  const { t } = useTranslation();

  return (
    <div className='rules-container'>
      <Helmet>
        <title>{t('SEO.kycPageTitle')}</title>
        <link rel='canonical' href={`https://dimmarex.com/kyc`} />
        <meta property='og:title' content={t('SEO.kycPageTitle')} />
        <meta name='description' content={t('SEO.kycPageDescription')} />
        <meta
          property='og:description'
          content={t('SEO.kycPageDescription')}
        />
      </Helmet>
      <div className='left-sidebar-background-img'></div>
      <div className='rules-wrapper'>
        <div className='section-title-container'>
          <FontAwesomeIcon icon={faShieldAlt} className='title-icon' />
          <h1>
            <div dangerouslySetInnerHTML={{ __html: t('kyc.title') }}></div>
          </h1>
        </div>
        <div className='content-container'>
          <h3>{t('kyc.generalProvisions')}</h3>
          <p>{t('kyc.generalProvisionsDesc')}</p>

          <h3>{t('kyc.identificationProcedure')}</h3>
          <p>{t('kyc.identificationProcedureDesc')}</p>

          <h3>{t('kyc.operationMonitoring')}</h3>
          <p>{t('kyc.operationMonitoringDesc')}</p>

          <h3>{t('kyc.staffTraining')}</h3>
          <p>{t('kyc.staffTrainingDesc')}</p>
        </div>
      </div>
      <div className='right-sidebar-background-img'></div>
    </div>
  );
};

export default KYC;
