import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { Input, Error, InputContainer, IconWrapper } from './style/formStyle';
import { EyeIcon, EyeOffIcon } from 'lucide-react';

const FormField = ({ name, control, rules, placeholder, type = 'text' }) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <InputContainer>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field, fieldState: { error } }) => (
          <>
            <Input
              error={!!error}
              {...field}
              type={type === 'password'? !showPassword ? 'password' : 'text': type}
              placeholder={placeholder}
            />
            {type === 'password' && (
              <IconWrapper onClick={togglePasswordVisibility}>
                {showPassword ? <EyeOffIcon /> : <EyeIcon />}
              </IconWrapper>
            )}
            {error && <Error>{error.message}</Error>}
          </>
        )}
      />
    </InputContainer>
  );
};

export default FormField;
