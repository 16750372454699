import axios from 'axios';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const FAILURE_RESPONSE = { success: false };

const getRequest = async (endpoint) => {
  try {
    const response = await axios.get(`${API_ENDPOINT}${endpoint}`, {
      headers: {
        Accept: 'application/json',
      },
    });
    if (response.status === 200) {
      return {
        success: true,
        data: response.data,
      };
    } else {
      return FAILURE_RESPONSE;
    }
  } catch (error) {
    return FAILURE_RESPONSE;
  }
};

export const fetchReviews = async (page = 1) => {
  const responseData = await getRequest(`review/?page=${page}`);
  if (responseData.success) {
    responseData.total = responseData.data.count;
    responseData.currentPage = page;
    responseData.lastPage = Math.ceil(responseData.data.count / 10); // предполагая, что размер страницы составляет 10
    responseData.data = responseData.data.results;
  }
  return responseData;
};

export const fetchCountries = async () => {
  const response = await getRequest('countries/');
  return response
};

export const fetchCountryEntities = async (countryId) => {
  return await getRequest(`cities_currencies/?country=${countryId}`);
};

export const fetchRate = async (fromCurrencyId, toCurrencyId, cityId) => {
  return getRequest(
    `get_rate/?from_currency_id=${fromCurrencyId}&to_currency_id=${toCurrencyId}&city_id=${cityId}`,
  );
};

export const fetchLastsExchanges = async () => {
  return await getRequest('last_exchanges/');
};

export const fetchNetworkLables = async () => {
  return await getRequest('network-lables/');
};

export const fetchArticles = async () => {
  return await getRequest('articles/');
};

export const fetchLearningMaterials = async () => {
  return await getRequest('learning-materials/');
};

export const fetchArticleDetails = async (slug) => {
  return await getRequest(`article/${slug}`);
};

export const fetchCityOfficeInfo = async (sityName) => {
  return await getRequest(`city-office-info/${sityName}/`);
};