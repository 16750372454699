import { Helmet } from 'react-helmet';
import './../../styles/contacts.css';
import './../../styles/reviews.css';

import { useTranslation } from 'react-i18next';
import RegisterForm from './RegisterForm';
import { SIGN_UP } from '../../routes/paths';

const SignUpPage = () => {
  const { t } = useTranslation();

  return (
    <div className='contacts-wrapper'>
      <Helmet>
        <title>{t('SEO.signUpPageTitle')}</title>
        <meta name='description' content={t('SEO.signUpPageDescription')} />
        <link rel='canonical' href={`${t('SEO.url')}${SIGN_UP}`} />
        <meta property='og:title' content={t('SEO.signUpPageTitle')} />
        <meta
          property='og:description'
          content={t('SEO.signUpPageDescription')}
        />
      </Helmet>
      <div className='left-sidebar-background-img'></div>
      <RegisterForm />
      <div className='right-sidebar-background-img'></div>
    </div>
  );
};

export default SignUpPage;
