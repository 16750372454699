import './../styles/404.css'; // Обновите путь к вашему CSS файлу
import Particles from 'react-tsparticles';
import AstronautImg from './../img/astronaut.svg';
import PlanetImg from './../img/planet.svg';
import { useTranslation } from 'react-i18next';

const particlesConfig = {
  fpsLimit: 60,
  particles: {
    number: {
      value: 160,
      density: {
        enable: true,
        area: 800,
      },
    },
    color: {
      value: '#ffffff',
    },
    shape: {
      type: 'circle',
    },
    opacity: {
      value: 1,
      random: {
        enable: true,
        minimumValue: 0.1,
      },
      animation: {
        enable: true,
        speed: 1,
        minimumValue: 0,
        sync: false,
      },
    },
    size: {
      value: 3,
      random: {
        enable: true,
        minimumValue: 1,
      },
    },
    move: {
      enable: true,
      speed: 0.17,
      direction: 'none',
      random: true,
      straight: false,
      outModes: {
        default: 'out',
      },
    },
  },
  interactivity: {
    detectsOn: 'canvas',
    events: {
      resize: false,
    },
  },
  detectRetina: true,
};

const NotFound = () => {
  const { t } = useTranslation();
  return (
      <div className='not-found'>
        <Particles id='tsparticles' options={particlesConfig} />
        <div className='permission_denied'>
        <div className='denied__wrapper'>
        
          <h1>404</h1>
          <h3>{t('NotFound')}</h3>
          <img id='astronaut' src={AstronautImg} alt='Astronaut' />{' '}
          {/* Обновите путь к вашему изображению */}
          <img id='planet' src={PlanetImg} alt='Planet' />{' '}
          {/* Обновите путь к вашему изображению */}
          <a href='/'>
            <button className='denied__link'>Go Home</button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
