import React from 'react';
import './styles/title.css';
import { useTranslation } from 'react-i18next';

const Title = ({ tagline }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className='title-container'>
        <div className='title-text'>
          <div
            dangerouslySetInnerHTML={{ __html: t('main.WelcomeMessageH1') }}
          ></div>
        </div>
      </div>

      <div className='title-description'>
        <p 
          dangerouslySetInnerHTML={{
            __html: tagline || t('main.WelcomeMessageTitle'),
          }}
        ></p>
      </div>
    </>
  );
};

export default Title;
