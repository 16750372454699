import React from 'react';
import FloatingLabelInput from './FloatingLabelInput';

const FeedbackForm = ({
  fields,
  submitLabel,
  onFormSubmit,
  register,
  errors,
  handleSubmit,
}) => {
  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      {fields.map((field) => (
        <div key={field.id}>
          {field.type === 'custom' ? (
            // Если тип поля "custom", то рендерим переданный компонент
            field.component
          ) : (
            <FloatingLabelInput
              type={field.type}
              id={field.id}
              placeholder={field.placeholder}
              {...register(field.id, { required: field.required })}
            />
          )}
          {errors[field.id] && <p>{errors[field.id].message}</p>}
        </div>
      ))}
      <button type='submit' className='contact-button' >{submitLabel}</button>
    </form>
  );
};

export default FeedbackForm;

