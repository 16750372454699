/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import AuthService from '../../../service/AuthService';
import { ClipLoader } from 'react-spinners';
import usdtImg from '../../../img/currencyIcons/Tether TRC20 (USDT).svg';
import styled from '@emotion/styled';
import PeriodFilter from './Filter';
import { calculatePeriod } from '../../../utils/tools';

export const StatsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  width: 100%;
  margin-top: 20px;
  max-width: 800px;
`;

export const StatCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  align-items: center;
  text-align: center;
  transition: transform 0.3s;
`;

export const StatTitle = styled.div`
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 10px;
`;

export const StatValue = styled.div`
  display: flex;
  gap: 0.5rem;
  font-size: 2rem;
  font-weight: bold;
  color: #333;
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const DownloadButton = styled.button`
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #45a049;
  }
`;

const UserStats = () => {
  const { t } = useTranslation();
  const [stats, setStats] = useState(null);
  const [period, setPeriod] = useState('all_time');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const fetchUserStats = async () => {
    try {
      const params = {
        period,
        start_date: startDate || undefined,
        end_date: endDate || undefined,
      };
      const response = await AuthService.getUserStats(params);
      setStats(response.data);
    } catch (error) {
      console.error('Error fetching user stats', error);
    }
  };

  useEffect(() => {
    fetchUserStats();
  }, [period, startDate, endDate]);

  const downloadExchanges = async () => {
    try {
      const response = await AuthService.downloadUserExchanges();
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `user_exchanges.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading the exchanges', error);
    }
  };

  const handleFilterChange = (newPeriod, newStartDate, newEndDate) => {
    setPeriod(newPeriod);
    setStartDate(newStartDate || '');
    setEndDate(newEndDate || '');
  };
  const periodDates = calculatePeriod(period, startDate, endDate);

  return (
    <div className='left-dash-container'>
      <PeriodFilter onFilterChange={handleFilterChange} />
      {periodDates && (
        <div className='users-title-period m-t-0'>
          Период: {`${periodDates.startDate} - ${periodDates.endDate}`}
        </div>
      )}
      {stats ? (
        <>
          <StatsContainer>
            <StatCard>
              <StatTitle>{t('dashboard.stats.totalEarnings')}</StatTitle>
              <StatValue>
                {stats.total_earnings}{' '}
                <img src={usdtImg} alt='usdt' style={{ width: '35px' }} />
              </StatValue>
            </StatCard>
            <StatCard>
              <StatTitle>{t('dashboard.stats.totalDeals')}</StatTitle>
              <StatValue>{stats.total_deals}</StatValue>
            </StatCard>
            <StatCard>
              <StatTitle>{t('dashboard.stats.openDeals')}</StatTitle>
              <StatValue>{stats.open_deals}</StatValue>
            </StatCard>
            <StatCard>
              <StatTitle>{t('dashboard.stats.closedDeals')}</StatTitle>
              <StatValue>{stats.closed_deals}</StatValue>
            </StatCard>
            <StatCard>
              <StatTitle>{t('dashboard.stats.canceledDeals')}</StatTitle>
              <StatValue>{stats.canceled_deals}</StatValue>
            </StatCard>
            <StatCard>
              <StatTitle>{t('dashboard.stats.refLinkCount')}</StatTitle>
              <StatValue>{stats.ref_link_count}</StatValue>
            </StatCard>
          </StatsContainer>
          <DownloadButton onClick={downloadExchanges}>
            {t('dashboard.downloadExchanges')}
          </DownloadButton>
        </>
      ) : (
        <LoaderContainer>
          <ClipLoader size={100} color={'#9DFBFE'} />
        </LoaderContainer>
      )}
    </div>
  );
};

export default UserStats;
