import { Helmet } from 'react-helmet';
import './../../styles/contacts.css';
import './../../styles/reviews.css';

import { useTranslation } from 'react-i18next';
import PasswordResetForm from './PasswordResetForm';
import { PASSWORD_RESET } from '../../routes/paths';


export const ResetPasswordSuccessPage = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className='main-dash-container'>
        <div className='section-title-container'>
          <h1>{t('auth.resetPassword')}</h1>
        </div>
        <p>{t('auth.resetPasswordSuccess')}</p>
      </div>
    </>
  );
};

const RessetPasswordPage = () => {
  const { t } = useTranslation();

  return (
    <div className='contacts-wrapper'>
      <Helmet>
        <title>{t('SEO.RessetPasswordPageTitle')}</title>
        <meta
          name='description'
          content={t('SEO.RessetPasswordPageDescription')}
        />
        <link rel='canonical' href={`${t('SEO.url')}${PASSWORD_RESET}`} />
        <meta property='og:title' content={t('SEO.RessetPasswordPageTitle')} />
        <meta
          property='og:description'
          content={t('SEO.RessetPasswordPageDescription')}
        />
      </Helmet>
      <div className='left-sidebar-background-img'></div>
      <PasswordResetForm />
      <div className='right-sidebar-background-img'></div>
    </div>
  );
};

export default RessetPasswordPage;
