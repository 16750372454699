import { format, parseISO } from 'date-fns';
import { enUS, ru } from 'date-fns/locale';

const locales = {
  en: enUS,
  ru: ru,
};


const formatDate = (dateString, language) => {
    const date = parseISO(dateString);
    const locale = locales[language] || enUS; // Если нет соответствующей локали, используйте enUS
    return format(date, "d MMMM yyyy 'г.' HH:mm", { locale: locale });
  };
  
  
  export default formatDate;