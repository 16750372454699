import { Controller, useForm } from 'react-hook-form';
import { Button, Error, Form } from '../style/formStyle';
import AuthService from '../../../service/AuthService';
import { useContext, useState } from 'react';
import { Context } from '../../..';
import { useTranslation } from 'react-i18next';
import { ClipLoader } from 'react-spinners';
import { useNotification } from '../../../context/NotificationContext';

const Password = () => {
  const { notify } = useNotification();

  const [hasValue, setHasValue] = useState(false);

  const { store } = useContext(Context);
  const { t } = useTranslation();

  const handleInputChange = (e) => {
    setHasValue(e.target.value !== '');
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    if (data.new_password !== data.new_password_repeat) {
      alert(t('auth.passwordsDoNotMatch'));
      return;
    }

    store.setLoading(true);
    try {
      await AuthService.updatePassword(data);
      notify({
        message: t('dashboard.successUpdated'),
        type: 'success',
      });
    } catch (error) {
      console.error(error);
      if (error.response && error.response.status === 400) {
        const data = error.response.data;
        let firstDataValue;

        for (const key in data) {
          if (data.hasOwnProperty(key)) {
            firstDataValue = data[key];
            break;
          }
        }
        notify({
          message: firstDataValue,
          type: 'error',
        });
      }
    }
    store.setLoading(false);
  };

  return (
    <div className='left-dash-container'>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name='old_password'
          control={control}
          rules={{ required: t('auth.passwordRequired') }}
          render={({ field }) => (
            <div className='dash-input-container'>
              <input
                type='password'
                className='form-input'
                onChange={handleInputChange}
                {...field}
                placeholder={t('auth.oldPassword')}
              />
              <label className={`input-label ${hasValue ? 'has-value' : ''}`}>
                {t('auth.oldPassword')}
              </label>
            </div>
          )}
        />
        {errors.old_password && <Error>{errors.old_password.message}</Error>}
        <Controller
          name='new_password'
          control={control}
          rules={{ required: t('auth.passwordRequired') }}
          render={({ field }) => (
            <div className='dash-input-container'>
              <input
                type='password'
                className='form-input'
                onChange={handleInputChange}
                {...field}
                placeholder={t('auth.newPassword')}
              />
              <label className={`input-label ${hasValue ? 'has-value' : ''}`}>
                {t('auth.newPassword')}
              </label>
            </div>
          )}
        />
        {errors.new_password && <Error>{errors.new_password.message}</Error>}
        <Controller
          name='new_password_repeat'
          control={control}
          rules={{ required: t('auth.passwordRequired') }}
          render={({ field }) => (
            <div className='dash-input-container'>
              <input
                type='password'
                className='form-input'
                onChange={handleInputChange}
                {...field}
                placeholder={t('auth.newPasswordRepeat')}
              />
              <label className={`input-label ${hasValue ? 'has-value' : ''}`}>
                {t('auth.newPasswordRepeat')}
              </label>
            </div>
          )}
        />
        {errors.new_password_repeat && (
          <Error>{errors.new_password_repeat.message}</Error>
        )}
        <Button type='submit' disabled={store.isLoading}>
          {store.isLoading ? (
            <ClipLoader size={20} color={'#9DFBFE'} />
          ) : (
            t('dashboard.btn')
          )}
        </Button>
      </Form>
    </div>
  );
};

export default Password;
