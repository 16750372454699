import { useTranslation } from 'react-i18next';
import './styles/welcomeSection.css';
import React, { useEffect } from 'react';
import { fillTemplate } from '../utils/formatArticleTitle';
import { replacePlaceholderWithLink } from '../../utils/tools';

const BaseCryptoExchangeProcess = ({
  transactionProcedure,
  handleScrollToExchange,
}) => {
  const stepClasses = {
    1: '',
    2: 'priorities',
    3: '',
    4: 'call-to-action',
  };

  useEffect(() => {
    replacePlaceholderWithLink(
      'exchangeLinkPlaceholder',
      handleScrollToExchange,
      '#exchange-form-id',
      'exchange-link',
    );
  }, [handleScrollToExchange]);

  return (
    <div className='welcome-container'>
      <div className='content-block-container'>
        <h2
          className='block-title-h2'
          dangerouslySetInnerHTML={{ __html: transactionProcedure.title }}
        ></h2>
        <div className='content-block-container m-o'>
          {Object.keys(transactionProcedure).map((key, index) => {
            if (key !== 'title') {
              const step = transactionProcedure[key];
              const stepClass = stepClasses[key] || '';
              return (
                <div key={index} className={`conten-img-wrapper ${stepClass}`}>
                  <div className='content-block'>
                    <div className='content-block__number'>{index + 1}</div>
                    <h4>{step.title}</h4>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: step.description,
                      }}
                    ></p>
                  </div>
                </div>
              );
            }
            return null;
          })}
        </div>
      </div>
    </div>
  );
};

const CryptoExchangeProcess = () => {
  const { t } = useTranslation();
  const transactionProcedure = t('transactionProcedure', {
    returnObjects: true,
  });
  return (
    <BaseCryptoExchangeProcess transactionProcedure={transactionProcedure} />
  );
};

export const CityCryptoExchangeProcess = ({
  handleScrollToExchange,
  cityText,
}) => {
  const { t } = useTranslation();
  const transactionProcedure = fillTemplate(
    t(`usdtTemplates.${cityText.template}.transactionProcedure`, {
      returnObjects: true,
    }),
    cityText,
  );
  return (
    <BaseCryptoExchangeProcess transactionProcedure={transactionProcedure} handleScrollToExchange={handleScrollToExchange}/>
  );
};

export default CryptoExchangeProcess;
