// routes/public.tsx
import DashBoardPage from '../feature/dashboard/DashBoardPage';
import Balance from '../feature/dashboard/components/Balance';
import Password from '../feature/dashboard/components/Password';
import UserData from '../feature/dashboard/components/UserData';
import UserStats from '../feature/dashboard/components/UserStats';
import { DASHBOARD, PASSWORD, STATISTIC, BALANCE } from './paths';
// Импортируйте другие публичные страницы здесь

export const protectedRoutes = [
  {
    path: DASHBOARD,
    element: <DashBoardPage />,
    children: [
      { index: true, element: <UserData /> },
      { path: PASSWORD, element: <Password /> },
      { path: STATISTIC, element: <UserStats /> },
      { path: BALANCE, element: <Balance /> },
    ],
  },
];
