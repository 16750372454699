import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGavel } from '@fortawesome/free-solid-svg-icons';
import DashBoardSideBar from './DashBoardSideBar';
import { Outlet } from 'react-router-dom';

const DashBoardPage = () => {
  const { t } = useTranslation();
  const dashboard = t('dashboard', { returnObjects: true });

  return (
    <div className='main-dash-container'>
      <div className='section-title-container'>
        <FontAwesomeIcon icon={faGavel} className='title-icon' />
        <h1>
          <div dangerouslySetInnerHTML={{ __html: dashboard.title }}></div>
        </h1>
      </div>
      <div className='dashboard-container'>
        <Outlet />
        <DashBoardSideBar />
      </div>
    </div>
  );
};

export default DashBoardPage;
