const svgContext = require.context('../../img/currencyIcons', false, /\.(svg|png|eps)$/);

const currencyIcons = svgContext.keys().reduce((icons, iconPath) => {
  const key = iconPath.split('/').pop().split('.')[0];
  
  icons[key] = svgContext(iconPath);

  return icons;
}, {});

export default currencyIcons;