import { format, subDays, subMonths, startOfDay, endOfDay } from 'date-fns';

export const calculatePeriod = (period, startDate, endDate) => {
  let start, end;
  const now = new Date();

  if (period === 'week') {
    end = endOfDay(now);
    start = startOfDay(subDays(now, 6)); // Вычитаем 6 дней, чтобы получить неделю
  } else if (period === 'month') {
    end = startOfDay(subDays(now, 1));
    start = startOfDay(subMonths(end, 1));
  } else if (period === 'custom' && startDate && endDate) {
    return {
      startDate: format(new Date(startDate), 'dd.MM.yyyy'),
      endDate: format(new Date(endDate), 'dd.MM.yyyy'),
    };
  } else if (period === 'all_time') {
    return null;
  }

  return {
    startDate: format(start, 'dd.MM.yyyy'),
    endDate: format(end, 'dd.MM.yyyy'),
  };
};

export const replacePlaceholderWithLink = (
  placeholderId,
  handleScrollToExchange,
  linkHref,
  linkClass,
) => {
  const linkPlaceholder = document.getElementById(placeholderId);
  if (linkPlaceholder) {
    const link = document.createElement('a');
    link.href = linkHref;
    link.className = linkClass;
    link.onclick = handleScrollToExchange;
    link.innerHTML = linkPlaceholder.innerHTML;
    linkPlaceholder.parentNode.replaceChild(link, linkPlaceholder);
  }
};
