import { useContext, useEffect, useState } from 'react';
import { Context } from '../../..';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ClipLoader } from 'react-spinners';
import { observer } from 'mobx-react-lite';
import { Form, Error, Button, UserInfo } from '../style/formStyle';
import AuthService from '../../../service/AuthService';
import { useNotification } from '../../../context/NotificationContext';


const UserData = () => {
  const { notify } = useNotification();
  const [hasValue, setHasValue] = useState(false);

  const { store } = useContext(Context);
  const { t } = useTranslation();

  const profile = store.userProfile;

  const handleInputChange = (e) => {
    setHasValue(e.target.value !== '');
  };

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: profile,
  });

  useEffect(() => {
    if (profile) {
      reset(profile);
    }
  }, [profile, reset]);

  const onSubmit = async (data) => {
    store.setLoading(true);
    try {
      await AuthService.updateProfile(data);
      notify({
        message: t('dashboard.successUpdated'),
        type: 'success',
      });
    } catch (error) {
      notify({
        message: t('dashboard.error'),
        type: 'error',
      });
    }

    store.setLoading(false);
  };

  return (
    <div className='left-dash-container'>
      <UserInfo>
        <div>{t('auth.username')}: {store.user.username}</div>
        <div>{t('dashboard.commission')}: {profile.commission_rate}</div>
      </UserInfo>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name='email'
          control={control}
          rules={{ required: t('auth.emailRequired') }}
          render={({ field }) => (
            <div className='dash-input-container'>
              <input
                type='email'
                className='form-input'
                onChange={handleInputChange}
                {...field}
                placeholder={t('auth.email')}
              />
              <label className={`input-label ${hasValue ? 'has-value' : ''}`}>
                {t('auth.email')}
              </label>
            </div>
          )}
        />
        {errors.title && <Error>{errors.email.message}</Error>}
        <Controller
          name='first_name'
          control={control}
          render={({ field }) => (
            <div className='dash-input-container'>
              <input
                type='text'
                className='form-input'
                onChange={handleInputChange}
                {...field}
                placeholder={t('dashboard.first_name')}
              />
              <label className={`input-label ${hasValue ? 'has-value' : ''}`}>
                {t('dashboard.first_name')}
              </label>
            </div>
          )}
        />
        {errors.title && <Error>{errors.first_name.message}</Error>}
        <Controller
          name='last_name'
          control={control}
          render={({ field }) => (
            <div className='dash-input-container'>
              <input
                type='text'
                className='form-input'
                onChange={handleInputChange}
                {...field}
                placeholder={t('dashboard.last_name')}
              />
              <label className={`input-label ${hasValue ? 'has-value' : ''}`}>
                {t('dashboard.last_name')}
              </label>
            </div>
          )}
        />
        {errors.title && <Error>{errors.last_name.message}</Error>}
        <Controller
          name='phone_number'
          control={control}
          render={({ field }) => (
            <div className='dash-input-container'>
              <input
                type='text'
                className='form-input'
                onChange={handleInputChange}
                {...field}
                placeholder={t('dashboard.phone_number')}
              />
              <label className={`input-label ${hasValue ? 'has-value' : ''}`}>
                {t('dashboard.phone_number')}
              </label>
            </div>
          )}
        />
        {errors.title && <Error>{errors.phone_number.message}</Error>}
        <Controller
          name='wallet_address'
          control={control}
          render={({ field }) => (
            <div className='dash-input-container'>
              <input
                type='text'
                className='form-input'
                onChange={handleInputChange}
                {...field}
                placeholder={t('dashboard.wallet_address')}
              />
              <label className={`input-label ${hasValue ? 'has-value' : ''}`}>
                {t('dashboard.wallet_address')}
              </label>
            </div>
          )}
        />
        {errors.title && <Error>{errors.wallet_address.message}</Error>}
        <Button type='submit' disabled={store.isLoading}>
          {store.isLoading ? (
            <ClipLoader size={20} color={'#9DFBFE'} />
          ) : (
            t('dashboard.btn')
          )}
        </Button>
      </Form>
    </div>
  );
};

export default observer(UserData);
