import { useState, useEffect } from 'react';
import './hompage/styles/hompage.css';
import Exchange from './hompage/Exchange';

import { useTranslation } from 'react-i18next';
import { fetchLastsExchanges, fetchNetworkLables } from '../api/get';
import LastExchanges from './hompage/LastExchanges';
import { Helmet } from 'react-helmet';
import Cities from './hompage/Cities';
import Title from './hompage/Title';
import { fetchCountries } from '../api/get';
import { useParams, useSearchParams } from 'react-router-dom';
import TgBotInfo from './hompage/TgBotInfo';
import Partners from './hompage/Partners';
import { CityContent, fillTemplate } from './utils/formatArticleTitle';


const CryptoCity = () => {
  const { cityName } = useParams();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const [exchanges, setExchanges] = useState([]);
  const [countries, setCountries] = useState([]);
  const [networkLables, setNetworkLables] = useState({});

  const fromCurrency = searchParams.get('cur_from') || '';
  const toCurrency = searchParams.get('cur_to');
  const city = searchParams.get('city');

  const cityText = {
    baseCity: t(`cryptoCity.${cityName}.baseCity`),
    city: t(`cryptoCity.${cityName}.city`),
    country: t(`cryptoCity.${cityName}.country`),
    curr: t(`cryptoCity.${cityName}.curr`),
  };

  useEffect(() => {
    const getCountries = async () => {
      const response = await fetchCountries();
      if (response.success) {
        setCountries(response.data);
      }
      const networkLablesResponese = await fetchNetworkLables();
      if (networkLablesResponese.success) {
        setNetworkLables(networkLablesResponese.data);
      }
    };
    getCountries();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchLastsExchanges();
        setExchanges(response.data.results);
      } catch (error) {
        setExchanges(null);
      }
    };

    fetchData();
  }, []);

  return (
    <div className='hompage-wrapper'>
      <Helmet>
        <title>
          {t(`SEO.getBitcoinTitle`)} {t(`cryptoCity.${cityName}.city`)}
        </title>
        <meta
          name='description'
          content={`${t('SEO.bitcoinCityDescription')} ${t(
            `cryptoCity.${cityName}.city`,
          )}`}
        />
        <link
          rel='canonical'
          href={`https://dimmarex.com/bitcoin/${cityName}`}
        />
        <meta
          property='og:title'
          content={`${t('SEO.getBitcoinTitle')} ${t(
            `cryptoCity.${cityName}.city`,
          )}`}
        />
        <meta
          property='og:description'
          content={`${t('SEO.bitcoinCityDescription')} ${t(
            `cryptoCity.${cityName}.city`,
          )}`}
        />
      </Helmet>
      <div className='exchange-wrapper'>
        <div className='content-with-left-sidebar'> </div>
        <div className='exchange-section  form-section'>
          <div className='exchange-title'>
            <h1
              dangerouslySetInnerHTML={{
                __html: fillTemplate(t('bitcoin.baseText.Title'), cityText),
              }}
            ></h1>
            <h2
              dangerouslySetInnerHTML={{
                __html: fillTemplate(t('bitcoin.baseText.subTitle'), cityText),
              }}
            ></h2>
          </div>
          <div className='exchange-container'>
            <div className='exchange-container-name'>{t('main.exchange')}</div>

            <div className='neutral-div'>
              <Exchange
                countries={countries}
                fromCurrencyURL={fromCurrency}
                toCurrencyURL={toCurrency}
                networkLables={networkLables}
                cityURL={city || cityName}
                urlPath={`/bitcoin/${city || cityName}/exchange?`}
              />
            </div>
          </div>
          <Title
            tagline={fillTemplate(t('bitcoin.baseText.Tagline'), cityText)}
          ></Title>
        </div>
        {exchanges && exchanges.length > 0 && (
          <LastExchanges exchanges={exchanges} />
        )}
        <div className='content-with-right-sidebar'> </div>
      </div>
      <div className='city-wrapper'>
        <div className='left-sidebar-background-img'></div>
        <div className='city-container'>
          <CityContent
            title={fillTemplate(t('bitcoin.baseText.Title2'), cityText)}
            description={fillTemplate(
              t('bitcoin.baseText.description'),
              cityText,
            )}
          />
          <CityContent
            title={fillTemplate(t('bitcoin.baseText.Title3'), cityText)}
            description={fillTemplate(
              t('bitcoin.baseText.description2'),
              cityText,
            )}
          />
          <CityContent
            title={fillTemplate(t('bitcoin.baseText.Title4'), cityText)}
            description={fillTemplate(
              t('bitcoin.baseText.description3'),
              cityText,
            )}
          />
          <TgBotInfo />
        </div>
        <div className='right-sidebar-background-img'></div>
      </div>
      <Cities countries={countries} />
      <Partners/>
    </div>
  );
};


export default CryptoCity;