export const countryFlagDict = {
    'Albania': '🇦🇱',
    'Argentina': '🇦🇷',
    'Armenia': '🇦🇲',
    'Australia': '🇦🇺',
    'Austria': '🇦🇹',
    'Azerbaijan': '🇦🇿',
    'Bahrain': '🇧🇭',
    'Belarus': '🇧🇾',
    'Belgium': '🇧🇪',
    'Brazil': '🇧🇷',
    'Bulgaria': '🇧🇬',
    'Canada': '🇨🇦',
    'Chile': '🇨🇱',
    'China': '🇨🇳',
    'Colombia': '🇨🇴',
    'Costa Rica': '🇨🇷',
    'Croatia': '🇭🇷',
    'Cyprus': '🇨🇾',
    'Czechia': '🇨🇿',
    'Denmark': '🇩🇰',
    'Dominican Republic': '🇩🇴',
    'Ecuador': '🇪🇨',
    'Egypt': '🇪🇬',
    'El Salvador': '🇸🇻',
    'Estonia': '🇪🇪',
    'Finland': '🇫🇮',
    'France': '🇫🇷',
    'Georgia': '🇬🇪',
    'Germany': '🇩🇪',
    'Greece': '🇬🇷',
    'Haiti': '🇭🇹',
    'Hong Kong': '🇭🇰',
    'Hungary': '🇭🇺',
    'Indonesia': '🇮🇩',
    'Iran': '🇮🇷',
    'Ireland': '🇮🇪',
    'Israel': '🇮🇱',
    'Italy': '🇮🇹',
    'Kazakhstan': '🇰🇿',
    'Kyrgyzstan': '🇰🇬',
    'Latvia': '🇱🇻',
    'Libya': '🇱🇾',
    'Lithuania': '🇱🇹',
    'Luxembourg': '🇱🇺',
    'Malta': '🇲🇹',
    'Mexico': '🇲🇽',
    'Moldova': '🇲🇩',
    'Monaco': '🇲🇨',
    'Montenegro': '🇲🇪',
    'Netherlands': '🇳🇱',
    'North Macedonia': '🇲🇰',
    'Norway': '🇳🇴',
    'Panama': '🇵🇦',
    'Peru': '🇵🇪',
    'Poland': '🇵🇱',
    'Portugal': '🇵🇹',
    'Qatar': '🇶🇦',
    'Romania': '🇷🇴',
    'Russia': '🇷🇺',
    'Saudi Arabia': '🇸🇦',
    'Serbia': '🇷🇸',
    'Singapore': '🇸🇬',
    'Slovakia': '🇸🇰',
    'Slovenia': '🇸🇮',
    'South Korea': '🇰🇷',
    'Spain': '🇪🇸',
    'Sweden': '🇸🇪',
    'Switzerland': '🇨🇭',
    'Tajikistan': '🇹🇯',
    'Thailand': '🇹🇭',
    'Tunisia': '🇹🇳',
    'Turkey': '🇹🇷',
    'Ukraine': '🇺🇦',
    'United Arab Emirates': '🇦🇪',
    'United Kingdom': '🇬🇧',
    'United States': '🇺🇸',
    'Uzbekistan': '🇺🇿',
    'Japan': '🇯🇵',
    'Malaysia': '🇲🇾'
}

export const getCountryLabel = (countryName) => (
    <>
      {countryFlagDict[countryName]} {countryName}
    </>
);
