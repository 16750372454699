// AmountInputComponent.jsx
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';

const AmountInput = ({
  control,
  rate,
  register,
  fromCurrency,
  toCurrency,
  setValue,
  errors,
}) => {
  const [enteredAmount, setEnteredAmount] = useState('');
  const [convertedAmount, setConvertedAmount] = useState('');
  const [lastModifiedField, setLastModifiedField] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (rate && rate.rate) {
      if (lastModifiedField === 'entered' && enteredAmount) {
        const newAmount = (enteredAmount / rate.rate).toFixed(3);
        setConvertedAmount(newAmount);
        setValue('give_quantity', enteredAmount);
        setValue('get_quantity', newAmount);
      } else if (lastModifiedField === 'converted' && convertedAmount) {
        const newAmount = (convertedAmount * rate.rate).toFixed(3);
        setEnteredAmount(newAmount);
        setValue('give_quantity', newAmount);
        setValue('get_quantity', convertedAmount);
      }
    }
  }, [enteredAmount, convertedAmount, rate, lastModifiedField, setValue]);
  // Обработка изменений введенной суммы
  const handleEnteredAmountChange = (e) => {
    setEnteredAmount(e.target.value);
    setConvertedAmount('');
    setLastModifiedField('entered');
  };

  // Обработка изменений конвертированной суммы
  const handleConvertedAmountChange = (e) => {
    setConvertedAmount(e.target.value);
    setEnteredAmount('');
    setLastModifiedField('converted');
  };

  const validateAmount = (value) => {
    const floatValue = parseFloat(value);
    if (floatValue < rate.min_sum) {
      return `Минимальная сумма должна быть не менее ${rate.min_sum}`;
    } else if (floatValue > rate.max_sum) {
      return `Максимальная сумма должна быть не более ${rate.max_sum}`;
    }
    return true;
  };

  return (
    <div className='exchange-info'>
    <div className='exchange-row'>
      <div className='rate-info'>
        <div className='input-group'>
          <p className='transfer-text'>{t('main.get')}</p>
          <div className='exchange-input-container'>
            <Controller
              control={control}
              name='give_quantity'
              rules={{
                required: 'Это поле обязательно для заполнения',
                validate: validateAmount
              }}
              render={({ field, fieldState: { error } }) => (
                <input
                  {...field}
                  type='number'
                  className={`exchange-input ${
                    error ? 'exchange-input-error' : ''
                  }`}
                  placeholder={rate.give}
                  onChange={(e) => {
                    field.onChange(e);
                    handleEnteredAmountChange(e);
                  }}
                  value={enteredAmount}
                />
              )}
            />
            {errors.give_quantity && (
              <span className='error-form-message'>
                {errors.give_quantity.message}
              </span>
            )}

            <span className='currency-label'>
              {fromCurrency.label}
            </span>
          </div>
        </div>
        <div className='input-group'>
          <p className='transfer-text'>{t('main.give')}</p>
          <div className='exchange-input-container'>
            <input
              required
              className='exchange-input'
              type='number'
              value={convertedAmount}
              onChange={handleConvertedAmountChange}
              placeholder={rate.get}
              name='get_quantity'
              register={register}
            />
            <span className='currency-label'>
              {toCurrency.label}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
};

export default AmountInput;
