import { Helmet } from 'react-helmet';
import './../../styles/contacts.css';
import './../../styles/reviews.css';

import { useTranslation } from 'react-i18next';
import LoginForm from './LoginForm';

const SigInPage = () => {
  const { t } = useTranslation();
  
  return (
    <div className='contacts-wrapper'>
      <Helmet>
        <title>{t('SEO.signInPageTitle')}</title>
        <meta name='description' content={t('SEO.signInPageDescription')} />
        <link rel='canonical' href={`${t('SEO.url')}sign-in`} />
        <meta property='og:title' content={t('SEO.signInTitle')} />
        <meta
          property='og:description'
          content={t('SEO.signInPageDescription')}
        />
      </Helmet>
      <div className='left-sidebar-background-img'></div>
          <LoginForm />
      <div className='right-sidebar-background-img'></div>
    </div>
  );
};

export default SigInPage;
