import $api from '../http';

export default class AuthService {
  static async login(username, password) {
    return $api.post('/auth/token/', {
      username: username,
      password: password,
    });
  }
  static async logout() {
    return $api.post('/auth/logout/');
  }
  static async register(data) {
    return $api.post('/auth/user/', data);
  }
  static async getProfile() {
    return $api.get('/auth/user-data/');
  }
  static async getUserStats(params) {
    return $api.get('/auth/exchange-stats/', { params });
  }
  static async updateProfile(data) {
    return $api.put('/auth/user-data/', data);
  }
  static async updatePassword(data) {
    return $api.post('/auth/change-password/', data);
  }
  static async resetPassword(data) {
    return $api.post('/auth/password-reset/', data);
  }
  static async draftBalance() {
    return $api.post('/auth/create-balance-order/');
  }
  static async downloadUserExchanges() {
    return $api.get('/auth/download-exchanges/', {
      responseType: 'blob',
    });
  }
}
