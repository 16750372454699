import { useContext } from 'react';
import { Context } from '..';
import { publicRoutes, commonRoutes } from './public';
import { protectedRoutes } from './protected';
import { observer } from 'mobx-react-lite';
import { Navigate, Route, Routes } from 'react-router-dom';
import { DASHBOARD, SIGN_IN } from './paths';

const ProtectedRoute = observer(({ element }) => {
  const { store } = useContext(Context);
  return store.isAuth ? element : <Navigate to={SIGN_IN} replace />;
});

const PublicRoute = observer(({ element }) => {
  const { store } = useContext(Context);
  return store.isAuth ? <Navigate to={DASHBOARD} replace /> : element;
});

const AppRoutes = () => {

  return (
    <Routes>
      {protectedRoutes.map((route, index) => (
        <Route
          key={index}
          path={route.path}
          element={<ProtectedRoute element={route.element} />}
        >
          {route.children &&
            route.children.map((childRoute, childIndex) => (
              <Route
                key={childIndex}
                index={childRoute.index}
                path={childRoute.path}
                element={<ProtectedRoute element={childRoute.element} />}
              />
            ))}
        </Route>
      ))}
      {publicRoutes.map((route, index) => (
        <Route
          key={index}
          path={route.path}
          element={<PublicRoute element={route.element} />}
        />
      ))}
      {commonRoutes.map((route, index) => (
        <Route key={index} {...route} />
      ))}
    </Routes>
  );
};

export default observer(AppRoutes);
