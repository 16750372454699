const cashCurrencyRegex = /^Cash/;
export const isCashCurrency = (currencyName) =>
  cashCurrencyRegex.test(currencyName);

export const isBankRequisite = (currency) =>
  currency && !isCashCurrency(currency.label) && !currency.isCrypto;

export const isCryptoCurrency = (currency) => currency && currency.isCrypto;

export const isTrc20WalletAddress = (address) => {
  return /^T[A-Za-z1-9]{33}$/.test(address);
};

export const isErc20Bep20WalletAddress = (address) => {
  return /^0x[a-fA-F0-9]{40}$/.test(address);
};

export const isBitcoinWalletAddress = (address) => {
  // Проверка для P2PKH и P2SH адресов
  const legacyAddress = /^[13][a-km-zA-HJ-NP-Z1-9]{25,34}$/.test(address);
  // Проверка для Bech32 адресов (начинается с bc1 и может содержать q, p, z, r, y, и 1-9)
  const bech32Address = /^bc1[qzry9x8gf2tvdw0s3jn54khce6mua7l]{39,59}$/.test(address);
  return legacyAddress || bech32Address;
};



export const isValidCardNumber = (value) => {
  // Удаляем все пробелы из входного значения
  const cleanValue = value.replace(/\s+/g, '');

  const regex = new RegExp('^[0-9]{13,19}$');
  if (!regex.test(cleanValue)) return false;

  let sum = 0;
  let shouldDouble = false;
  // Проходимся по номеру карты справа налево
  for (let i = cleanValue.length - 1; i >= 0; i--) {
    let digit = parseInt(cleanValue.charAt(i));

    if (shouldDouble) {
      if ((digit *= 2) > 9) digit -= 9;
    }

    sum += digit;
    shouldDouble = !shouldDouble;
  }

  return sum % 10 === 0;
};
