import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import { isValidCardNumber } from '../../utils/validator';

const BankRequisiteInput = ({ control, errors }) => {
  const { t } = useTranslation();

  const formatCardNumber = (value) => {
    // Удаляем все, кроме цифр
    let onlyNums = value.replace(/\D/g, '');
    // Добавляем пробел каждые 4 цифры
    return onlyNums.replace(/(\d{4})(?=\d)/g, '$1 ');
  };

  return (
    <>
      <Controller
        name='card_number'
        id='bank-card-input'
        control={control}
        rules={{
          required: t('main.fieldRequired'),
          validate: (value) =>
            isValidCardNumber(value) || t('main.invalidCardNumber'),
        }}
        render={({ field, fieldState: { error } }) => (
          <div className='input-container'>
            <input
              {...field}
              required
              type='text'
              className={`user-data-input ${
                error ? 'exchange-input-error' : ''
              }`}
              placeholder={t('main.cardNumberPlaceholder')}
              onInput={(e) => {
                const formattedInput = formatCardNumber(e.target.value);
                e.target.value = formattedInput;
              }}
            />
            <label>{t('main.cardNumberPlaceholder')}</label>
            {errors.card_number && (
              <span className='error-form-message input-error'>
                {errors.card_number.message}
              </span>
            )}{' '}
          </div>
        )}
      />
    </>
  );
};

export default BankRequisiteInput;
