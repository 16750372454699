import React from 'react';

const PopupMessage = ({
  isSuccess,
  isError,
  hideMessage,
  successMessage,
  errorMessage,
}) => {
  return (
    <>
      {isSuccess && (
        <div
          className={`success-popup ${isSuccess ? 'show' : ''} ${
            hideMessage ? 'hide' : ''
          }`}
        >
          <span>{successMessage}</span>
        </div>
      )}
      {isError && (
        <div
          className={`error-popup ${isError ? 'show' : ''} ${
            hideMessage ? 'hide' : ''
          }`}
        >
          <span>{errorMessage}</span>
        </div>
      )}
    </>
  );
};

export default PopupMessage;
