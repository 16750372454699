import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { fetchArticles } from '../../api/get';
import './../../styles/article.css';
import { formatArticleTitle } from '../utils/formatArticleTitle';


const Articles = () => {
  const [articles, setArticles] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    const getArticles = async () => {
      const response = await fetchArticles();
      if (response.success) {
        setArticles(response.data);
      }
    };
    getArticles();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <div className='rules-container'>
      <Helmet>
        <title>{t('SEO.articlesTitle')}</title>
        <link rel='canonical' href={`${t('SEO.url')}articles`} />
        <meta
          name='description'
          content={t('SEO.articlesPageDescription')}
        />
        <meta property='og:title' content={t('SEO.articlesTitle')} />
        <meta
          property='og:description'
          content={t('SEO.articlesPageDescription')}
        />
      </Helmet>

      <div className='left-sidebar-background-img wigth-10'></div>

      <div className='rules-wrapper'>
        <div className='section-title-container'>
          <h1>
            <div dangerouslySetInnerHTML={{ __html: t('article.title') }}></div>
          </h1>
        </div>
        <div className='grid-container'>
          {articles.map((article, index) => (
            <div key={article.id} className='grid-item'>
              <a href={`/article/${article.slug}`} rel='noopener noreferrer'>
              {formatArticleTitle(`${index + 1}. ${article.title}`)}
              </a>
            </div>
          ))}
        </div>
      </div>
      <div className='right-sidebar-background-img wigth-10'></div>
    </div>
  );
};

export default Articles;
