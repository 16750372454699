export const formatArticleTitle = (title) => {
    const parts = title.split(/:(.+)/);
    if (parts.length > 1) {
      return (
        <>
          <span>{parts[0]}:</span>{parts[1]}
        </>
      );
    } else {
      return title;
    }
  };


  export function fillTemplate(template, data) {
    if (typeof template === 'string') {
      return template.replace(/{(\w+)}/g, (match, key) => {
        return key in data ? data[key] : match;
      });
    }
  
    if (typeof template === 'object' && template !== null) {
      const result = Array.isArray(template) ? [] : {};
      for (const key in template) {
        if (template.hasOwnProperty(key)) {
          result[key] = fillTemplate(template[key], data);
        }
      }
      return result;
    }
  
    return template;
  }
  
export const CityContent = ({ title, description }) => {
    const startsWithLi = description.trim().startsWith('<li>');
  
    return (
      <div className='city-content'>
        <div className='city-title'>
          <h2 dangerouslySetInnerHTML={{ __html: title }}></h2>
        </div>
        <div className='city-description'>
          {startsWithLi ? (
            <div className='bot-info-wrapper'>
              <ul
                className='bot-info-container'
                dangerouslySetInnerHTML={{ __html: description }}
              />
            </div>
          ) : (
            <>
              <div dangerouslySetInnerHTML={{ __html: description }} />
            </>
          )}
        </div>
      </div>
    );
  };
