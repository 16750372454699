import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { Context } from '../..';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import FormField from './FormField';
import { AuthContainer, Form, Button, PrimaryLink } from './style/formStyle';
import { useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';

import './style/auth.css';
import { PASSWORD_RESET_SUCCESS, SIGN_IN } from '../../routes/paths'; // Импортируйте путь к странице входа

const PasswordResetForm = () => {
  const { t } = useTranslation();
  const { control, handleSubmit } = useForm();
  const { store } = useContext(Context);
  const navigate = useNavigate();

  const [resetError, setResetError] = useState('');

  const onSubmit = async (data) => {
    store.setLoading(true);
    try {
      await store.resetPassword(data);
      navigate(PASSWORD_RESET_SUCCESS);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setResetError(() => t('auth.emailNotFound'));
      } else {
        setResetError(() => t('auth.loginError'));
      }
    }
    store.setLoading(false);
  };

  return (
    <div className='app-container'>
      <div className='container-name form-container-name black-bg'>
        <h1>{t('auth.resetPassword')}</h1>
      </div>

      <AuthContainer>
        {resetError && <div className='error-message'>{resetError}</div>}

        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormField
            name='email'
            control={control}
            rules={{ required: t('auth.emailRequired') }}
            placeholder={t('auth.email')}
            type='email'
          />
          <Button type='submit' disabled={store.isLoading}>
            {store.isLoading ? (
              <ClipLoader size={20} color={'#9DFBFE'} />
            ) : (
              t('auth.resetPasswordBtn')
            )}
          </Button>
        </Form>
        <div className='auth-link-section'>
          <PrimaryLink to={SIGN_IN}>{t('auth.backToSignIn')}</PrimaryLink>
        </div>
      </AuthContainer>
    </div>
  );
};

export default observer(PasswordResetForm);
