import { useState, useEffect, useRef } from 'react';
import './hompage/styles/hompage.css';
import Exchange from './hompage/Exchange';

import { useTranslation } from 'react-i18next';
import { fetchLastsExchanges, fetchNetworkLables } from '../api/get';
import LastExchanges from './hompage/LastExchanges';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Cities from './hompage/Cities';
import Title from './hompage/Title';
import { fetchCountries } from '../api/get';
import { useSearchParams } from 'react-router-dom';
import Partners from './hompage/Partners';
import CityOfficeInfo from './elements/CityOfficeInfo';
import { fillTemplate } from './utils/formatArticleTitle';
import { CityAdvantages } from './hompage/Advantages';
import { CityWelcomeSection } from './hompage/WelcomeComponent';
import { CityCryptoExchangeProcess } from './hompage/CryptoExchangeProcess';
import CompanyInfo from './hompage/companyInfo';

const CityHompage = () => {
  const { cityName } = useParams();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const [exchanges, setExchanges] = useState([]);
  const [countries, setCountries] = useState([]);
  const [networkLables, setnetworkLables] = useState({});

  const fromCurrency = searchParams.get('cur_from') || '';
  const toCurrency = searchParams.get('cur_to');
  const city = searchParams.get('city');

  const cityText = {
    baseCity: t(`cryptoCity.${cityName}.baseCity`),
    city: t(`cryptoCity.${cityName}.city`),
    curr: t(`cryptoCity.${cityName}.curr`),
    template: t(`cryptoCity.${cityName}.usdtTemplate`),
  };

  useEffect(() => {
    const getCountries = async () => {
      const response = await fetchCountries();
      if (response.success) {
        setCountries(response.data);
      }
      const networkLablesResponese = await fetchNetworkLables();
      if (networkLablesResponese.success) {
        setnetworkLables(networkLablesResponese.data);
      }
    };
    getCountries();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchLastsExchanges();
        setExchanges(response.data.results);
      } catch (error) {
        setExchanges(null);
      }
    };

    fetchData();
  }, []);

  const exchangeRef = useRef(null);

  const handleScrollToExchange = (e) => {
    e.preventDefault();
    const elementTop = exchangeRef.current.getBoundingClientRect().top;
    const halfScreenHeight = window.innerHeight / 2;
    window.scroll({
      top: elementTop + window.pageYOffset - halfScreenHeight,
      behavior: 'smooth',
    });
  };

  return (
    <div className='hompage-wrapper'>
      <Helmet>
        <title>
          {t(`SEO.getUsdtTitle`)} {t(`cryptoCity.${cityName}.city`)}
        </title>
        <meta
          name='description'
          content={`${t('SEO.usdtCityDescription')} ${t(
            `cryptoCity.${cityName}.city`,
          )}`}
        />
        <link rel='canonical' href={`https://dimmarex.com/usdt/${cityName}`} />
        <meta
          property='og:description'
          content={`${t('SEO.usdtCityDescription')} ${t(
            `cryptoCity.${cityName}.city`,
          )}`}
        />
        <meta
          property='og:title'
          content={`${t('SEO.getUsdtTitle')} ${t(
            `cryptoCity.${cityName}.city`,
          )}`}
        />
      </Helmet>
      <div className='exchange-wrapper'>
        <div className='content-with-left-sidebar'> </div>
        <div className='exchange-section  form-section'>
          <div className='exchange-title'>
            <h1
              dangerouslySetInnerHTML={{
                __html: fillTemplate(
                  t(`usdtTemplates.${cityText.template}.Title`),
                  cityText,
                ),
              }}
            ></h1>
            <h2
              dangerouslySetInnerHTML={{
                __html: fillTemplate(
                  t(`usdtTemplates.${cityText.template}.subTitle`),
                  cityText,
                ),
              }}
            ></h2>
          </div>
          <div className='exchange-container'>
            <div className='exchange-container-name'>{t('main.exchange')}</div>

            <div className='neutral-div' ref={exchangeRef}>
              <Exchange
                countries={countries}
                fromCurrencyURL={fromCurrency}
                toCurrencyURL={toCurrency}
                networkLables={networkLables}
                cityURL={city || cityName}
                urlPath={`/usdt/${city || cityName}/exchange?`}
              />
            </div>
          </div>
          <Title></Title>
        </div>
        {exchanges && exchanges.length > 0 && (
          <LastExchanges exchanges={exchanges} />
        )}

        <div className='content-with-right-sidebar'> </div>
      </div>
      <div className='advantage-wrapper'>
        <div className='city-container'>
          <CityWelcomeSection cityText={cityText} />
          <CityAdvantages
            handleScrollToExchange={handleScrollToExchange}
            cityText={cityText}
          />
          <CityCryptoExchangeProcess
            handleScrollToExchange={handleScrollToExchange}
            cityText={cityText}
          />
          <CompanyInfo cityText={cityText} />{' '}
          <CityOfficeInfo cityName={cityName} />
        </div>
      </div>

      <Cities countries={countries} />
      <Partners />
    </div>
  );
};

export default CityHompage;
