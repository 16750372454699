import { useTranslation } from 'react-i18next';

const Politica = () => {
  const { t } = useTranslation();

  return (
    <div className='rules-wrapper'>
      <h1 className='section-title-container'><div dangerouslySetInnerHTML={{ __html: t('privacyPolicyh1') }}></div></h1>
      <div className='content-container pre-wrap-text'>
        {t('privacyPolicy')}
      </div>
    </div>
  );
};

export default Politica;