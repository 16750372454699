import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './../style/filter.css'

const PeriodFilter = ({ onFilterChange }) => {
  const { t } = useTranslation();
  const [period, setPeriod] = useState('all_time');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const handlePeriodChange = (e) => {
    setPeriod(e.target.value);
    if (e.target.value !== 'custom') {
      setStartDate('');
      setEndDate('');
      onFilterChange(e.target.value);
    }
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const handleApplyCustomFilter = () => {
    if (startDate && endDate) {
      onFilterChange('custom', startDate, endDate);
    }
  };

  return (
    <div className='filter'>
      <select value={period} onChange={handlePeriodChange} className='filter__input-field '>
        <option value='all_time'>{t('dashboard.filters.allTime')}</option>
        <option value='week'>{t('dashboard.filters.lastWeek')}</option>
        <option value='month'>{t('dashboard.filters.lastMonth')}</option>
        <option value='custom'>{t('dashboard.filters.custom')}</option>
      </select>
      {period === 'custom' && (
        <div className='custom-date-filter'>
          <input type='date' value={startDate} onChange={handleStartDateChange} />
          <input type='date' value={endDate} onChange={handleEndDateChange} />
          <button className='apply-button' onClick={handleApplyCustomFilter}>
            Применить
          </button>
        </div>
      )}
    </div>
  );
};

export default PeriodFilter;
