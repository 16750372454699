import { useTranslation } from 'react-i18next';
import defaultBlockImg from '../../img/advantageIcons/defaultBlock.svg';
import TgBotInfo from './TgBotInfo';
import './styles/advantages.css';
import { fillTemplate } from '../utils/formatArticleTitle';

const FirstBlock = () => {
  const { t } = useTranslation();

  return (
    <div className='advantages-container b-white mw-50'>
      <p>{t('companyInfo.firstBlockDesc')}</p>
      <a
        href='https://t.me/DIMMAR_EX'
        target='_blank'
        rel='noopener noreferrer'
        className='telegram-button m-w-40'
      >
        {t('companyInfo.firstBlockBtn')}
      </a>
    </div>
  );
};

const SecondBlock = ({ blocks }) => {
  return (
    <div className='advantages-container b-white'>
      <div className='advantages-container__row'>
        <div>
          <div className='adv-content-block b-grey'>
            <img
              src={defaultBlockImg}
              alt='defaultBlockImg'
              width={32}
              height={32}
            ></img>
            <h3
              className='font-regular-20 font-gr'
              dangerouslySetInnerHTML={{ __html: blocks.block1.title }}
            ></h3>
            <p dangerouslySetInnerHTML={{ __html: blocks.block1.desc }}></p>
          </div>
          <div className='adv-content-block b-grey'>
            <img
              src={defaultBlockImg}
              alt='defaultBlockImg'
              width={32}
              height={32}
            ></img>
            <h3
              className='font-regular-20 font-gr'
              dangerouslySetInnerHTML={{ __html: blocks.block3.title }}
            ></h3>
            <p dangerouslySetInnerHTML={{ __html: blocks.block3.desc }}></p>
          </div>
        </div>

        <div>
          <div className='adv-content-block b-grey'>
            <img
              src={defaultBlockImg}
              alt='defaultBlockImg'
              width={32}
              height={32}
            ></img>
            <h3
              className='font-regular-20 font-gr'
              dangerouslySetInnerHTML={{ __html: blocks.block2.title }}
            ></h3>
            <p dangerouslySetInnerHTML={{ __html: blocks.block2.desc }}></p>
          </div>
          <div className='adv-content-block b-grey'>
            <img
              src={defaultBlockImg}
              alt='defaultBlockImg'
              width={32}
              height={32}
            ></img>
            <h3
              className='font-regular-20 font-gr'
              dangerouslySetInnerHTML={{ __html: blocks.block4.title }}
            ></h3>
            <p dangerouslySetInnerHTML={{ __html: blocks.block4.desc }}></p>
          </div>
        </div>
      </div>
    </div>
  );
};

const CompanyInfo = ({ cityText }) => {
  const { t } = useTranslation();

  let blocks;

  // Проверяем существование и значение cityText.template
  if (cityText && cityText.template) {
    blocks = fillTemplate(
      t(`usdtTemplates.${cityText.template}.companyInfo`, {
        returnObjects: true,
      }),
      cityText,
    );
  } else {
    blocks = t('companyInfo.secondBlockTexts', { returnObjects: true });
  }
  return (
    <div className='advantage-wrapper'>
      <div className='left-sidebar-background-img'></div>

      <div className='advantage-content'>
        <h2
          className='block-title-h2'
          dangerouslySetInnerHTML={{ __html: t('companyInfo.title') }}
        ></h2>
        <FirstBlock />
        <h2
          className='block-title-h2'
          dangerouslySetInnerHTML={{ __html: t('companyInfo.title2') }}
        ></h2>
        <SecondBlock blocks={blocks} />
        <TgBotInfo />
      </div>

      <div className='right-sidebar-background-img'></div>
    </div>
  );
};

export default CompanyInfo;
