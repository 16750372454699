const selectStyles = {
  control: (base) => ({
    ...base,
    display: 'flex',
    width: '100%',
    padding: '10px',
    borderRadius: '5px',
    borderColor: '#8b7575',
    backgroundColor: 'transparent',
    height: '40px',
    alignItems: 'center',
    marginBottom: '10px',
  }),
  placeholder: (base) => ({
    ...base,
    color: '#888',
    alignItems: 'center'
  }),
  singleValue: (base) => ({
    ...base,
    color: '#333',
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: '#8b7575',
    display: 'flex',
  }),
  menu: (base) => ({
    ...base,
    color: '#333',
    borderRadius: '5px',
    boxShadow: '0px 2px 10px rgba(0,0,0,0.1)',
  }),
  valueContainer: (base) => ({
    ...base,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0, // Удалить или настроить, чтобы убедиться, что это не вызывает проблем
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isSelected
      ? '#b7f3b3'
      : state.isFocused
      ? '#f5f5f5'
      : 'transparent',
    color: '#333',
    padding: '10px',
  }),
};

export default selectStyles;
