import { useEffect, useState } from 'react';
import { fetchLearningMaterials } from '../../api/get';
import { useTranslation } from 'react-i18next';
import './../hompage/styles/advantages.css';
import './../../styles/article.css'
import { Helmet } from 'react-helmet';


const LearningMaterialAccordionItem = ({ title, contents }) => {
  const [isActive, setIsActive] = useState(false);
  const activeClass = isActive ? 'active' : '';

  return (
    <div
      className={`accordion-item ${activeClass} material-container`}
      onClick={() => setIsActive(!isActive)}
    >
      <div className='accordion-title material-inner'>
        <div className='material-title'>{title}</div>
        <div>
          <span>{isActive ? '-' : '+'}</span>
        </div>
      </div>
      {isActive && (
        <div className='accordion-content'>
          {contents.map((content, index) => (
            <div key={index}>
              <h3 >{content.title}</h3>
              <p>{content.description}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const LearningMaterials = () => {
  const [learningMaterials, setLearningMaterials] = useState([]);

  const { t } = useTranslation();

  const getLearningMaterials = async () => {
    const response = await fetchLearningMaterials();
    if (response.success) {
      setLearningMaterials(response.data);
    }
  };

  useEffect(() => {
    getLearningMaterials();
  }, []);

  return (
    <div className='advantage-wrapper'>
      <Helmet>
        <title>{t('SEO.materialsTitle')}</title>
        <link rel='canonical' href={`${t('SEO.url')}learning-materials`} />
        <meta
          name='description'
          content={t('SEO.materialsPageDescription')}
        />
        <meta property='og:title' content={t('SEO.materialsTitle')} />
        <meta
          property='og:description'
          content={t('SEO.materialsPageDescription')}
        />
      </Helmet>
      <div class='left-sidebar-background-img'></div>
      <div className='section-title-container'>
        <h1>
          <div
            dangerouslySetInnerHTML={{
              __html: t('article.learningMaterialsTitle'),
            }}
          ></div>
        </h1>
        <div className='advantage-content top-50'>
          <div className='accordion-wrapper material-wrapper'>
            {learningMaterials.map((material, index) => (
              <div className=''>
                <div className='accordion-title material-inner'>
                  <LearningMaterialAccordionItem
                    key={index}
                    title={material.title}
                    contents={material.subtitles}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div class='right-sidebar-background-img'></div>
    </div>
  );
};

export default LearningMaterials;
