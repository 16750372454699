import styled from '@emotion/styled';

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  div {
    margin-bottom: 10px;
    font-size: 1.2em;
    font-weight: bold;
    color: #333;
  }
`;

export const Form = styled('form')`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 20px;
  margin-top: 20px;
  max-width: 800px;
`;

export const HelpText = styled('span')`
  color: #6c757d;
  font-size: 12px;
`;

export const Error = styled('span')`
  color: red;
  font-size: 12px;
`;

export const Button = styled('button')`
  margin-top: 20px;
  background-color: rgba(143, 119, 240, 1); /* Фиолетовый фон кнопки */
  color: #fff; /* Белый текст кнопки */
  border: none;
  border-radius: 5px; /* Скругление углов кнопки */
  padding: 10px 20px;
  font-size: 1rem; /* Размер шрифта кнопки */
  cursor: pointer; /* Курсор в виде указателя */
  font-weight: 600;
  cursor: pointer; /* чтобы показать, что это кликабельный элемент */
  transition: background 0.3s ease; /* плавный переход фона */
  text-align: center;
  width: 100%;
  max-width: 20rem;
  min-width: 10rem;
  min-height: 40px;


  &:hover {
    background-color: rgba(157, 251, 254, 1);
    color: #000;
    text-decoration: none;
  }
`;
