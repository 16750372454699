import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../style/formStyle';
import { ClipLoader } from 'react-spinners';
import AuthService from '../../../service/AuthService';
import { Context } from '../../..';
import { StatsContainer, StatCard, StatTitle, StatValue } from './UserStats';
import usdtImg from '../../../img/currencyIcons/Tether TRC20 (USDT).svg';
import { useNotification } from '../../../context/NotificationContext';
import { FaCopy } from 'react-icons/fa';
import { CopyToClipboard } from 'react-copy-to-clipboard';

export const APP_URL = process.env.REACT_APP_ENDPOINT;

const Balance = () => {
  const { t } = useTranslation();
  const { store } = useContext(Context);
  const { notify } = useNotification();

  const handleClick = async () => {
    if (store.userProfile.balance < 10) {
      notify({
        message: t('dashboard.smallSumError'),
        type: 'error',
      });
      return;
    }
    store.setLoading(true);
    try {
      await AuthService.draftBalance();
      notify({
        message: t('dashboard.successDraft'),
        type: 'success',
      });
    } catch (error) {
      if (error.response.status === 400) {
        notify({
          message: t('dashboard.errorDraft400'),
          type: 'error',
        });
      } else {
        notify({
          message: t('dashboard.error'),
          type: 'error',
        });
      }
    }

    store.setLoading(false);
  };
  const referralLink = `${APP_URL}exchange?agent_id=${store.userProfile.tg_id}`;

  return (
    <div className='left-dash-container'>
      <div className='ref-container'>
        <div className='referral-section'>
          <h2>{t('dashboard.refTitle')}</h2>
          <p>{t('dashboard.refDesc')}</p>
          <div className='referral-link-container'>
            <span className='referral-link'>
              {referralLink}
              <CopyToClipboard text={referralLink}>
                <button className='copy-button'>
                  <FaCopy />
                </button>
              </CopyToClipboard>
            </span>
          </div>
        </div>
        <div className='balance-container'>
          <StatsContainer>
            <StatCard>
              <StatTitle>{t('dashboard.balance')}</StatTitle>
              <StatValue>
                {store.userProfile.balance}{' '}
                <img src={usdtImg} alt='usdt' style={{ width: '35px' }} />
              </StatValue>
            </StatCard>
          </StatsContainer>
          <div>
            <Button disabled={store.isLoading} onClick={handleClick}>
              {store.isLoading ? (
                <ClipLoader size={20} color={'#9DFBFE'} />
              ) : (
                t('dashboard.drafBtn')
              )}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Balance;
