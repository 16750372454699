import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { Context } from '../..';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import FormField from './FormField';
import { AuthContainer, Form, Button, PrimaryLink } from './style/formStyle';
import { SIGN_IN } from '../../routes/paths';



const RegisterForm = () => {
    const { t } = useTranslation();
    const [registrationError, setRegistrationError] = useState('');
    const navigate = useNavigate();
    const { control, handleSubmit } = useForm();

    const { store } = useContext(Context);
    const onSubmit = async (formData) => {
        try {
          await store.register(formData);
    
          localStorage.setItem('flashMessage', t('auth.successRegister'));
          navigate(SIGN_IN);
        } catch (error) {
          console.log(error.response);
          if (error.response && error.response.status === 400) {
            const data = error.response.data;
            let firstDataValue;
    
            for (const key in data) {
              if (data.hasOwnProperty(key)) {
                firstDataValue = data[key];
                break;
              }
            }
    
            setRegistrationError(firstDataValue);
          } else {
            setRegistrationError(t('auth.registerError'));
          }
        }
      };
    
    return ( <div className='contact-section form-section'>
        <div className='container-name form-container-name black-bg'>
        <h1>{t('auth.signUpTitle')}</h1>
      </div>
        <AuthContainer>
          {registrationError && (
            <div className='error-message'>{registrationError}</div>
          )}
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FormField
              name='username'
              control={control}
              rules={{ required: t('auth.usernameRequired') }}
              placeholder={t('auth.loginPlaceholder')}
              type='text'
            />
            <FormField
              name='email'
              control={control}
              rules={{ required: t('auth.emailRequired') }}
              placeholder={t('auth.email')}
              type='email'
            />
            <FormField
              name='password'
              control={control}
              rules={{
                required: t('auth.passwordRequired'),
                minLength: {
                  value: 8,
                  message: t('auth.notValidPassword'),
                },
              }}
              placeholder={t('auth.password')}
              type='password'
            />
  
            <Button type='submit'>{t('auth.signUpBtn')}</Button>
          </Form>
          <div className='auth-link-section'>
          <PrimaryLink to={SIGN_IN}>{t('auth.signIn')}</PrimaryLink>
          </div>
        </AuthContainer>
      </div>
    );
}
 
export default observer(RegisterForm);