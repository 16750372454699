import React, { useState, useEffect, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAddressCard,
  faEnvelope,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import './../styles/contacts.css';
import './../styles/reviews.css';
import FeedbackForm from './utils/FeedBackForm';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { reviewSubmit } from '../api/post';
import PopupMessage from './utils/PopupMessage';
import useFormSubmission from './hooks/useFormSubmission';
import { fetchReviews } from '../api/get';
import formatDate from './utils/formatDate';
import { Helmet } from 'react-helmet';

const MAX_RATING = 5;
const StarsArray = (length) => [...Array(length)];

const StarRating = ({ onChange, register, setValue }) => {
  const [rating, setRating] = useState(MAX_RATING);
  const [hover, setHover] = useState();

  const handleStarClick = (index) => {
    setRating(index);
    setValue('stars', index); // обновляем значение input'а
    onChange(index);
  };

  return (
    <div className='star-rating-container'>
      <input type='hidden' name='stars' value={rating} ref={register()} />
      {StarsArray(MAX_RATING).map((_, index) => {
        index += 1;
        return (
          <span
            role='button'
            tabIndex={index}
            key={index}
            className={`star ${index <= (hover || rating) ? 'active' : ''}`}
            onClick={() => handleStarClick(index)}
            onMouseEnter={() => setHover(index)}
            onMouseLeave={() => setHover(0)}
          >
            ★
          </span>
        );
      })}
    </div>
  );
};

const Reviews = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm();

  const { isSuccess, isError, hideMessage, errorMessage, onSubmit } =
    useFormSubmission(
      reviewSubmit,
      t('flash.succesSend'),
      t('flash.errorReview'),
      reset,
    );
  const [rating, setRating] = useState(MAX_RATING);

  const [reviews, setReviews] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    setValue('stars', rating);
  }, [setValue, rating]);

  useEffect(() => {
    async function fetchReviewData() {
      const result = await fetchReviews(currentPage);

      if (result.success) {
        setReviews(result.data);
        setTotalPages(result.lastPage);
      } else {
        console.error(result.message);
      }
    }

    fetchReviewData();
  }, [currentPage]);

  const handlePrevPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
    window.scrollTo(0, 0); // прокручивает страницу к самому верху
  };

  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
    window.scrollTo(0, 0);
  };

  const fields = useMemo(
    () => [
      {
        type: 'text',
        id: 'email',
        placeholder: t('reviews.emailPlaceholder'),
        required: true,
      },
      {
        type: 'text',
        id: 'name',
        placeholder: t('reviews.namePlaceholder'),
        required: true,
      },
      {
        type: 'custom',
        id: 'stars',
        component: (
          <StarRating
            onChange={(value) => {
              setRating(value);
              setValue('stars', value);
            }}
            register={register}
            setValue={setValue}
          />
        ),
        required: true,
      },
      {
        type: 'textarea',
        id: 'message',
        placeholder: t('reviews.messagePlaceholder'),
        required: true,
      },
    ],
    [t, setRating, setValue, register],
  );

  return (
    <div className='contacts-wrapper'>
      <Helmet>
        <title>{t('SEO.reviewsPageTitle')}</title>
        <meta name='description' content={t('SEO.reviewsPageDescription')} />
        <link rel='canonical' href={`${t('SEO.url')}reviews`} />
        <meta property='og:title' content={t('SEO.reviewsPageTitle')} />
        <meta
          property='og:description'
          content={t('SEO.reviewsPageDescription')}
        />
      </Helmet>
      <div className='left-sidebar-background-img'></div>
      <div className='contact-section'>
        <div className='container-name'>
          <FontAwesomeIcon
            icon={faAddressCard}
            className='section-title-icon'
          />
          <h1>{t('reviews.OurReviews')}</h1>
        </div>
        <div className='flex-container'>
          <div className='contact-info'>
            <ul>
              {reviews?.map((review) => (
                <div key={review.id} className='review-item'>
                  {/* Иконка человечка */}
                  <FontAwesomeIcon icon={faUser} className='user-icon' />

                  <div className='review-content'>
                    <div className='header-content'>
                      <div className='time round'>
                        {formatDate(review.created_at, currentLanguage)}
                      </div>

                      {/* Имя */}
                      {/* Количество звезд (только для чтения) */}
                      <div className='readonly-stars'>
                        {StarsArray(MAX_RATING).map((_, index) => (
                          <span
                            key={index}
                            className={`star ${
                              index < review.stars ? 'active' : ''
                            }`}
                          >
                            ★
                          </span>
                        ))}
                      </div>
                      {/* Комментарий */}
                    </div>
                    <h4 className='review-name'>{review.name}</h4>
                    <div className='review-message'>{review.description}</div>
                  </div>
                </div>
              ))}
            </ul>
          </div>
        </div>
        {/* Пагинация */}
        <div className='pagination-buttons'>
          <button onClick={handlePrevPage} disabled={currentPage === 1}>
            {t('reviews.back')}
          </button>
          <span>
            {currentPage} из {totalPages}
          </span>
          <button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            {t('reviews.forward')}
          </button>
        </div>
      </div>

      {/* Отзывы */}
      <div className='contact-section form-section'>
        <div className='container-name form-container-name'>
          <FontAwesomeIcon
            icon={faEnvelope}
            bounce
            className='section-title-icon'
          />
          {t('reviews.sectionTitleFeedback')}
        </div>
        <div className='flex-container flex-form-container'>
          <div className='contact-form'>
            {/* Всплывающее сообщение */}
            <PopupMessage
              isSuccess={isSuccess}
              isError={isError}
              hideMessage={hideMessage}
              successMessage={t('flash.succesSend')}
              errorMessage={errorMessage || t('flash.error')}
            />

            <FeedbackForm
              fields={fields}
              submitLabel={t('reviews.submitLabel')}
              onFormSubmit={onSubmit}
              register={register}
              errors={errors}
              handleSubmit={handleSubmit}
            />
          </div>
        </div>
      </div>
      <div className='right-sidebar-background-img'></div>
    </div>
  );
};

export { formatDate };
export default Reviews;
