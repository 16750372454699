import { makeAutoObservable, flow } from 'mobx';
import AuthService from '../service/AuthService';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';
import { API_URL } from '../http';

export default class Store {
  user = {};
  userProfile = {};
  isAuth = false;
  isLoading = false;
  erorr = '';

  constructor() {
    makeAutoObservable(this);
  }
  setAuth(bool) {
    this.isAuth = bool;
  }
  setUser(user) {
    this.user = user;
  }

  setUserProfile(profile) {
    this.userProfile = profile;
  }

  setLoading(bool) {
    this.isLoading = bool;
  }

  setErorr(errMsg) {
    this.erorr = errMsg;
  }

  fetchAndUpdateUserProfile = flow(function* (accessToken) {
    const response = yield AuthService.getProfile();
    this.setUserProfile(response.data);
    const decodedToken = jwtDecode(accessToken);
    this.setUser(decodedToken);
    this.setAuth(true);
  });

  login = flow(function* (username, password) {
    try {
      this.setLoading(true);
      const response = yield AuthService.login(username, password);
      localStorage.setItem('access_token', response.data.access);
      Cookies.set('refresh_token', response.data.refresh, {
        expires: 30,
      });
      yield this.fetchAndUpdateUserProfile(response.data.access);
    } catch (error) {
      throw error;
    } finally {
      this.setLoading(false);
    }
  });

  async register(data) {
    return await AuthService.register(data);
  }
  async createReferral(data) {
    try {
      await AuthService.createReferral(data);
    } catch (error) {
    }
  }

  async logout() {
    try {
      this.setLoading(true);
      await AuthService.logout();
      localStorage.removeItem('access_token');
      this.setAuth(false);
      this.setUser({});
    } catch (error) {
    } finally {
      this.setLoading(false);
    }
  }

  chekcAuth = flow(function* () {
    try {
      this.setLoading(true);
      const refreshToken = Cookies.get('refresh_token');
      const response = yield axios.post(`${API_URL}auth/token/refresh/`, {
        refresh: refreshToken,
      });
      yield this.fetchAndUpdateUserProfile(response.data.access);
    } catch (error) {
      localStorage.removeItem('access_token');
      Cookies.remove('refresh_token');
    } finally {
      this.setLoading(false);
    }
  });

  async updateProfile(data) {
    this.setLoading(true);
    await AuthService.updateProfile(this.user.user_id, data);

    this.setUser(data);
    this.setLoading(false);
  }

  async resetPassword(data) {
    await AuthService.resetPassword(data);
  }
}
