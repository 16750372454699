import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGavel } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet';

const Rules = () => {
  const { t } = useTranslation();
  const rules = t('rules', { returnObjects: true });

  return (
    <div className='rules-container'>
      <Helmet>
        <title>{t('SEO.rulesPageTitle')}</title>
        <meta name='description' content={t('SEO.rulesPageDescription')} />
        <link rel='canonical' href={`${t('SEO.url')}rules`} />
        <meta property='og:title' content={t('SEO.rulesPageTitle')} />
        <meta
          property='og:description'
          content={t('SEO.rulesPageDescription')}
        />
      </Helmet>
      <div className='left-sidebar-background-img'></div>
      <div className='rules-wrapper'>
        <div className='section-title-container'>
          <FontAwesomeIcon icon={faGavel} className='title-icon' />
          <h1>
            <div dangerouslySetInnerHTML={{ __html: rules.title }}></div>
          </h1>
        </div>
        <div className='content-container'>
          {Object.keys(rules.sections).map((sectionKey) => {
            const section = rules.sections[sectionKey];
            return (
              <div key={sectionKey}>
                <h2>{section.title}</h2>
                {Object.keys(section.content).map((contentKey) => {
                  return <p key={contentKey}>{section.content[contentKey]}</p>;
                })}
              </div>
            );
          })}
        </div>
      </div>
      <div className='right-sidebar-background-img'></div>
    </div>
  );
};

export default Rules;
