import EtherImg from './../../img/ether.svg';

import { useTranslation } from 'react-i18next';
import './styles/welcomeSection.css';
import React from 'react';
import { fillTemplate } from '../utils/formatArticleTitle';

const BaseWelcomeSection = ({ title, description }) => {
  return (
    <div className='welcome-container'>
      <div className='content-block-container'>
        <h2 className='block-title-h2' dangerouslySetInnerHTML={{ __html: title }}></h2>
        <div className='conten-img-wrapper'>
          <div className='content-block'>
            <p dangerouslySetInnerHTML={{ __html: description }}></p>
          </div>
          <img src={EtherImg} alt="base img" width="200" height="200"></img>
        </div>
      </div>
    </div>
  );
};

const WelcomeSection = () => {
  const { t } = useTranslation();
  const title = t('AboutUs.title');
  const description = t('AboutUs.description');

  return (
    <BaseWelcomeSection 
      title={title} 
      description={description} 
    />
  );
};


export const CityWelcomeSection = ({ cityText }) => {
  const { t } = useTranslation();
  const title = fillTemplate(t(`usdtTemplates.${cityText.template}.welocomeSection.title`), cityText);
  const description = fillTemplate(t(`usdtTemplates.${cityText.template}.welocomeSection.desc`), cityText);

  return (
    <BaseWelcomeSection 
      title={title} 
      description={description} 
    />
  );
};
export default WelcomeSection;
