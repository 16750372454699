import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react-lite';

import './style.css';
import logoImage from './../../img/logo.svg';
import { NavLink } from 'react-router-dom';
import { useMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Context } from '../../index';
import { DASHBOARD } from '../../routes/paths';
// import useWindowSize from '../hooks/windowSize';

const NavItem = ({ to, children, onClick }) => {
  let match = useMatch(to);

  return (
    <li className={match ? 'active-link' : ''}>
      <NavLink to={to} onClick={onClick}>
        {children}
      </NavLink>
    </li>
  );
};

// ... (ваш импорт и другой код остается без изменений)

const Navbar = () => {
  const { store } = useContext(Context);

  const { t } = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <header>
      <div className='navbar'>
        <NavLink to='/' onClick={closeMenu}>
          <div className='navbar-logo'>
            <img
              src={logoImage}
              alt='Logo'
              className='logo'
              width='100'
              height='100'
            />
          </div>
        </NavLink>

        <div className='menu-toggle' onClick={toggleMenu}>
          <span className='bar'></span>
          <span className='bar'></span>
          <span className='bar'></span>
        </div>

        <nav className={`navbar-items ${menuOpen ? 'active' : ''}`}>
          <div className='close-menu' onClick={toggleMenu}>
            ✕
          </div>
          <ul>
            <NavItem to='/' onClick={closeMenu}>
              {t('navbar.home')}
            </NavItem>
            <NavItem to='/kyc' onClick={closeMenu}>
              {t('navbar.kyc')}
            </NavItem>
            <NavItem to='/reviews' onClick={closeMenu}>
              {t('navbar.reviews')}
            </NavItem>
            <NavItem to='/contacts' onClick={closeMenu}>
              {t('navbar.contacts')}
            </NavItem>
            <NavItem to='/rules' onClick={closeMenu}>
              {t('navbar.rules')}
            </NavItem>
            <NavItem to='/partnership' onClick={closeMenu}>
              {t('navbar.partnership')}
            </NavItem>
            <NavItem to='/articles' onClick={closeMenu}>
              {t('navbar.articles')}
            </NavItem>
          </ul>
          {store.isAuth && (
            <div className='auth-container'>
              <NavItem to={DASHBOARD} onClick={closeMenu}>
                {t('navbar.dashboard')}
              </NavItem>
            </div>
          )}
        </nav>
      </div>
    </header>
  );
};

export { NavItem };
export default observer(Navbar);
