import './styles/city.css';
import locationImg from './../../img/location-mark.svg';
import { useTranslation } from 'react-i18next';

const Cities = ({ countries }) => {
  const { t } = useTranslation();
  // Создаем массив всех городов
  const allCities = countries
    .flatMap((country) => country.cities)
    .sort((a, b) => a.name.localeCompare(b.name));

  return (
    <div className='cities-wrapper'>
      <div className='cities-container'>
        <div className='cities-title'>
          <h2 className='block-title-h2'>{t('main.citiesTitle')}</h2>
        </div>
        {allCities.map((city) => (
          <div key={city.id} className='city-item'>
            <img src={locationImg} alt='Location' width='28' height='28' />
            <a href={`/usdt/${city.name}`} rel='noopener noreferrer'>
              {t(`cryptoCity.${city.name}.baseCity`)}
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Cities;
