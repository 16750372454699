import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGavel } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet';
import { NavLink } from 'react-router-dom';

const Partnership = () => {
  const { t } = useTranslation();

  const partnership = t('partnership', { returnObjects: true });
  return (
    <div className='rules-container'>
      <Helmet>
        <link rel='canonical' href={`${t('SEO.url')}partnership`} />
        <title>{t('SEO.partnershipPageTitle')}</title>
        <meta
          name='description'
          content={t('SEO.partnershipPageDescription')}
        />
        <meta property='og:title' content={t('SEO.partnershipPageTitle')} />
        <meta
          property='og:description'
          content={t('SEO.partnershipPageDescription')}
        />
      </Helmet>
      <div className='left-sidebar-background-img'></div>
      <div className='rules-wrapper'>
        <div className='section-title-container'>
          <FontAwesomeIcon icon={faGavel} className='title-icon' />
          <h1>
            <div dangerouslySetInnerHTML={{ __html: partnership.title }}></div>
          </h1>
        </div>
        <div className='content-container'>
          {Object.keys(partnership.sections).map((sectionKey) => {
            const section = partnership.sections[sectionKey];
            return (
              <div key={sectionKey}>
                <h2>{section.title}</h2>
                {Object.keys(section.content).map((contentKey) => {
                  return (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: section.content[contentKey],
                      }}
                    ></div>
                  );
                })}
              </div>
            );
          })}
          <h2>{t('navbar.forPartnership')}</h2>
          <NavLink to='/sign-in'>{t('navbar.LK')}</NavLink>
        </div>
      </div>
      <div className='right-sidebar-background-img'></div>
    </div>
  );
};

export default Partnership;
