import React, { useState, forwardRef } from 'react';

const FloatingLabelInput = forwardRef(({ type, id, placeholder, ...props }, ref) => {
    const [hasValue, setHasValue] = useState(false);

    const handleInputChange = (e) => {
        setHasValue(e.target.value !== "");
    };

    return (
        <div className="input-container">
            <input 
                type={type === "textarea" ? "text" : type} 
                id={id} 
                className="contact-input" 
                placeholder={placeholder} 
                onChange={handleInputChange}
                ref={ref}
                {...props} 
                required 
            />
            <label htmlFor={id} className={`input-label ${hasValue ? "has-value" : ""}`}>{placeholder}</label>
        </div>
    );
});


export default FloatingLabelInput;

