import { useState } from 'react';

const useFormSubmission = (
  submitFunction,
  _,
  errorMessage,
  resetFunction,
) => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState(null); // Переименовано для ясности
  const [isError, setIsError] = useState(false);
  const [hideMessage, setHideMessage] = useState(false);

  const onSubmit = async (data) => {
    const result = await submitFunction(data);


    if (result.status === 201) {
      setIsSuccess(true);
      setIsError(false);

      setTimeout(() => {
        setHideMessage(true);
        setTimeout(() => {
          setIsSuccess(false);
          setHideMessage(false);
          resetFunction();
        }, 500);
      }, 3000);
    } else {
      setIsError(true);
      setError(errorMessage); // Установите сообщение об ошибке

      setTimeout(() => {
        setHideMessage(true);
        setTimeout(() => {
          setIsError(false);
          setHideMessage(false);
        }, 500);
      }, 3000);
    }
  };

  return {
    isSuccess,
    isError,
    hideMessage,
    errorMessage: error, // Возвращаем текущее сообщение об ошибке
    onSubmit,
  };
};

export default useFormSubmission;
