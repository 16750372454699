import React, { createContext, useContext, useState, useCallback } from 'react';
import ReactDOM from 'react-dom';

const NotificationContext = createContext(undefined);

export const useNotification = () => {
  const context = useContext(NotificationContext);
  if (context === undefined) {
    throw new Error(
      'useNotification must be used within a NotificationProvider',
    );
  }
  return context;
};

export const NotificationProvider = ({ children }) => {
  const [notification, setNotification] = useState(null);

  const notify = useCallback(({ message, type }) => {
    setNotification({ message, type });
    setTimeout(() => setNotification(null), 3000);
  }, []);

  const getNotificationStyle = (type) => {
    switch (type) {
      case 'success':
        return { backgroundColor: 'green', color: 'white' };
      case 'error':
        return { backgroundColor: 'red', color: 'white' };
      case 'info':
        return { backgroundColor: 'blue', color: 'white' };
      default:
        return {};
    }
  };

  return (
    <NotificationContext.Provider value={{ notify }}>
      {children}
      {notification &&
        ReactDOM.createPortal(
          <div
            style={{
              position: 'fixed',
              top: 20,
              right: 20,
              zIndex: 1000,
              padding: '10px',
              borderRadius: '5px',
              ...getNotificationStyle(notification.type),
            }}
          >
            {notification.message}
          </div>,
          document.body,
        )}
    </NotificationContext.Provider>
  );
};
