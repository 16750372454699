import { useState, useEffect } from 'react';
import { fetchArticleDetails } from '../../api/get';
import { useParams } from 'react-router-dom';
import './../../styles/article.css';
import { formatArticleTitle } from '../utils/formatArticleTitle';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';


const ArticleDetails = () => {
  const [article, setArticle] = useState();
  const { slug } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    const getArticle = async () => {
      const response = await fetchArticleDetails(slug);
      if (response.success) {
        setArticle(response.data);
      }
    };
    getArticle();
  }, [slug]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className='rules-container'>
      <Helmet>
        <title>{article?.title}</title>
        <link rel='canonical' href={`${t('SEO.url')}article/${slug}`} />
        <meta
          name='description'
          content={t('SEO.articlesPageDescription')}
        />
        <meta property='og:title' content={article?.title} />
        <meta
          property='og:description'
          content={t('SEO.articlesPageDescription')}
        />
      </Helmet>
      <div className='left-sidebar-background-img wigth-10'></div>
      {article ? (
        <div className='rules-wrapper'>
          <div className='section-title-container'>
            <h1>
              <div>{formatArticleTitle(article.title)}</div>
            </h1>
          </div>
          <div className='content-container article-container'>
            <p>{article.description}</p>
            {article.subtitles.map((subtitle) => (
              <>
                <h2>{subtitle.title}</h2>
                <div
                  className='article-container'
                  dangerouslySetInnerHTML={{ __html: subtitle.description }}
                ></div>
              </>
            ))}
            {article.table && (
              <>
                <h2>{article.table.title}</h2>
                <div className="extra-wrapper">
                <div className='table-responsive'>
                  <table className='table'>
                    <thead>
                      <tr>
                        {article.table.data.columns.map((column, index) => (
                          <th key={index}>{column}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {article.table.data.rows.map((row, index) => (
                        <tr key={index}>
                          <td><p>{row.td1}</p></td>
                          <td><p>{row.td2}</p></td>
                          <td><p>{row.td3}</p></td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                </div>
              </>
            )}
          </div>
        </div>
      ) : null}

      <div className='right-sidebar-background-img wigth-10'></div>
    </div>
  );
};

export default ArticleDetails;
