import axios from 'axios';
import Cookies from 'js-cookie';

export const API_URL = process.env.REACT_APP_API_ENDPOINT;

const $api = axios.create({
  withCredentials: true,
  baseURL: API_URL,
});

$api.interceptors.request.use((config) => {
  // Получаем токен из localStorage
  const token = localStorage.getItem('access_token');

  // Добавляем заголовок авторизации, если токен существует
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

$api.interceptors.response.use(
  (response) => response, // Эта функция вызывается для обработки успешных ответов
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      // Предполагается, что у вас есть эндпоинт для обновления токена
      const refreshToken = Cookies.get('refresh_token');
      const response = await axios.post(`${API_URL}auth/token/refresh/`, {
        refresh: refreshToken,
      });

      localStorage.setItem('access_token', response.data.access);
      return $api.request(originalRequest); // Повторный запрос с обновленным токеном
    }
    return Promise.reject(error);
  },
);

export default $api;
